import React from "react";
import { Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import CookbookSection from "../../components/cookbookSection";
import DutchOvenCookingTipsLink from "../../components/dutchOvenCookingTipsLink";

const DutchOvenDessertsCookbookSection = ({ location }) => {
  return (
    <CookbookSection
      location={location}
      sectionLink="dutch-oven-desserts"
      sectionName="Desserts for Dutch Oven"
      sectionTitle="Desserts for Dutch Oven"
      prevLink="/cookbook/desserts/"
      nextLink="/cookbook/main-meals/"
    >
      <Row className="d-flex pb-4 pt-4 flex-wrap justify-content-center" id="cookbook-top-text">
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div>Dutch oven desserts are the pinnacle of Dutch oven cooking</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/dutch-oven-in-fire2.jpg" alt="Dutch Oven in fire" />
          </div>
        </div>
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div className="flex-fill">... mic drop</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/tent-by-mountains2.jpg" alt="Tent by mountains" />
          </div>
        </div>
      </Row>
      <DutchOvenCookingTipsLink />
    </CookbookSection>
  );
};

export default DutchOvenDessertsCookbookSection;
