import React from "react";
import { Link } from "gatsby";
import { Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

const DutchOvenCookingTipsLink = () => {
  return (
    <Link to="/dutch-oven-cooking-tips/">
      <div className="d-flex justify-content-center mb-4 text-center">
        <div className="cookbook-dutch-oven-tips-container cookbook-dutch-oven-tips d-flex justify-content-center">
          <Row className="p-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="ps-2 pe-4">
                Check out our
                <br />
                Dutch Oven Cooking Tips!
              </div>
              <div>
                <StaticImage layout="fixed" src="../images/cookbook/dutch-oven-circle.png" alt="Tiny Dutch Oven" />
              </div>
            </div>
          </Row>
          <div className="cookbook-dutch-oven-tips-checkbox">
            <StaticImage layout="fixed" src="../images/cookbook/fa-checked-circle-white-ring.png" alt="White Circle" />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default DutchOvenCookingTipsLink;
